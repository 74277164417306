import React, { useState, useEffect } from 'react'

export default function CTAWidgetSelector(props) {
  const [Version, setVersion] = useState(null) //version of the component is stored in state

  const {
    businessData,
    configData,
    navLinks,
    // locationIndex,
    // locations,
    // city,
    // gonationID,
  } = props

  // dyanmically imports the right component to render depending on
  //  the version in the config provided
  const addComponent = async version => {
    await import(`./${version}/CTAWidget.js`)
      .then(component => setVersion(() => component.default)) // must set component as a function
      .catch(error => {
        console.error(`"${version}" not yet supported - ${error}`)
      })
  }

  useEffect(() => {
    addComponent(configData.ctaWidget.version) // runs onces on load
    return () => {}
  }, [configData])

  return (
    <div>
      {Version ? (
        <Version
          {...props}
          businessData={businessData}
          links={navLinks}
          variantName={configData.ctaWidget.variantName}
        />
      ) : (
        ''
      )}
    </div>
  )
}
