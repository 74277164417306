export default configData => {
  // an array to append correct links too
  const links = []

  // get the keys as they are used as the slugs
  const keys = Object.keys(configData)

  // check every value in the config for custome page name and if it exists append to list
  Object.values(configData).forEach((element, index) => {
    // if custom page name if filled use that name else use default key name.
    if (element !== null && typeof element === 'object') {
      element?.customPageName !== ''
        ? links.push(element.customPageName)
        : links.push(keys[index])
    } else {
      return undefined
    }
  })
  // change all names to lowercase ready to slugify
  links.forEach((link, index) => {
    if (link) {
      links[index] = link.toLowerCase()
    }
  })
  // return and filter out any objects without custom page name attribute. aka filter out undefined
  return links.filter(x => x !== undefined && x !== 'home')
  // removes undefined and home from nav array
}
