import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Heading } from 'theme-ui';
import { store } from '../../../../../../gatsby-theme-minimal/src/Context/Store';

export default function GlobalModal() {
  const { globalState, dispatch } = useContext(store);
  const { modalOpen, title, subtitle, text, html } = globalState?.modal;

  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const closeModal = () => {
    localStorage.setItem('modalSeen', 'true');
    dispatch({ type: 'CLOSE_MODAL' });
  };

  useEffect(() => {
    const seenPopUp = localStorage.getItem('modalSeen');
    if (!seenPopUp) {
      dispatch({ type: 'OPEN_MODAL' });
    }
    return () => {};
  }, []);

  const styles = {
    variant: 'customStyles.globalModal',
  };
  if (modalOpen) {
    return (
      <Box sx={{ ...customStyles.container, ...styles }} className="globalModal">
        <Box sx={customStyles.modalBackground} onClick={closeModal} />

        <Box sx={customStyles.content}>
          <div id="mc_embed_signup">
            <Box
              as="form"
              sx={customStyles.form}
              action="https://catskitchenco.us14.list-manage.com/subscribe/post?u=b8044e94ddfb369a75e95afe5&amp;id=69d07a6d8b"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              novalidate
            >
              <div id="mc_embed_signup_scroll">
                <h2>Subscribe To Cat's Kitchen</h2>
                <div class="indicates-required">
                  <span class="asterisk">*</span> indicates required
                </div>
                <div class="mc-field-group">
                  <label for="mce-EMAIL">
                    Email Address <span class="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    value={formState.email}
                    name="EMAIL"
                    class="required email"
                    id="mce-EMAIL"
                    onChange={e => {
                      setFormState({
                        ...formState,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
                <div class="mc-field-group">
                  <label for="mce-FNAME">First Name </label>
                  <input
                    type="text"
                    value={formState.firstName}
                    placeholder="Enter First Name"
                    name="FNAME"
                    class=""
                    id="mce-FNAME"
                    onChange={e => {
                      setFormState({
                        ...formState,
                        firstName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div class="mc-field-group">
                  <label for="mce-LNAME">Last Name </label>
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    name="LNAME"
                    class=""
                    id="mce-LNAME"
                    value={formState.lastName}
                    onChange={e => {
                      setFormState({
                        ...formState,
                        lastName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div id="mce-responses" class="clear foot">
                  <div class="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div class="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                  <input
                    type="text"
                    name="b_5678fce90d2f7419e2d965a12_3ee9284363"
                    tabindex="-1"
                    value=""
                  />
                </div>
                <div class="optionalParent">
                  <div class="clear foot">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      class="button"
                    />
                    {/* <p class='brandingLogo'>
                      <a
                        href='http://eepurl.com/h0alOr'
                        title='Mailchimp - email marketing made easy and fun'
                      >
                        <img src='https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg' />
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
            </Box>
          </div>

          {/* <Heading as='h3' sx={customStyles.title}>
            {title}
          </Heading>
          <Heading as='h4' sx={customStyles.subtitle}>
            {subtitle}
          </Heading>
          <Text as='p' sx={customStyles.text}>
            {text}
          </Text>
          <Box
            sx={customStyles.body}
            dangerouslySetInnerHTML={{ __html: html }}
          /> */}
        </Box>
      </Box>
    );
  } else {
    return '';
  }
}

const customStyles = {
  container: {
    position: 'fixed',
    top: '0rem',
    left: '0rem',
    width: '100%',
    height: '100vh',
    zIndex: '9000',
  },
  modalBackground: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'background',
    opacity: '0.7',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: '9100',
    minWidth: '300px',
    minHeight: '400px',
    width: '100%',
    maxWidth: '90%',
    maxHeight: '90vh',
    overflowY: 'scroll',
    backgroundColor: 'background',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2rem',
    boxShadow: '2px 2px 10px grey',
    // scrollbar styles
    '::-webkit-scrollbar': {
      width: '10px',
    },
    /* Track */
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },

    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },

  title: {
    variant: 'customVariants.title',
    textAlign: 'center',
  },
  subtitle: {
    variant: 'customVariants.subtitle',
    textAlign: 'center',
  },
  text: {
    variant: 'customVariants.text',
    textAlign: 'center',
  },
  body: {
    variant: 'customVariants.text',
    textAlign: 'center',
  },

  form: {
    h2: {
      variant: 'customVariants.title',
      margin: '0rem 0rem 1rem',
    },
    '.indicates-required': {
      color: 'red',
    },
    '.mc-field-group': {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '300px',
      margin: '0rem 0rem 1rem',
      label: {
        backgroundColor: 'white',
        transform: 'translateY(50%)',
        width: 'fit-content',
        fontSize: '0.8rem',
        marginLeft: '0.5rem',
        padding: '0.25rem',
      },
      input: {
        padding: '0.75rem 1rem',
      },
    },
    '.button': {
      variant: 'buttons.secondary',
      marginTop: '1.5rem',
    },
  },
};
