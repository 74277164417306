export default (url, width) => {
  // if (url) {
  //   const stringComponents = url.split('upload/')
  //   return `${stringComponents[0]}upload/w_${Math.round(width)}/q_auto/f_auto/${
  //     stringComponents[1]
  //   }`
  // } else {
  //   return '' // prevents crashes if nothing comes through
  // }

  // if (url) {
  //   return url
  // }

  if (url) {
    const stringComponents = url.split('upload/')
    return `${stringComponents[0]}upload/w_2500/q_auto/f_auto/${stringComponents[1]}`
  } else {
    return '' // prevents crashes if nothing comes through
  }
}

// This add /w_2000/q_auto/f_auto/ to the cloudnary image string after uploads.
// this allows us to customise the image quality that we recieve from cloudninary
// math.round is to prevent the image breaking if a decimal width size comes through.

//Before:   'https://res.cloudinary.com/gonation/image/upload/v1599250034/sites/thehubpizzabar/naugatuck-home.jpg'
//After:   "https://res.cloudinary.com/gonation/image/upload/w_2000/q_auto/f_auto/v1599250034/sites/thehubpizzabar/naugatuck-home.jpg"
