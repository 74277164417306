import React from 'react'
import ContactFormV1 from '../../../../../gatsby-theme-minimal/src/componentsList/ContactFormV1'
import Form from './v2/Form'
export default function ContactFormsContainer(props) {
  const {
    pageObjectKey,
    businessData,
    configData,
    // poweredImages,
    pageTitle,
    isMultiLocationSite,
  } = props

  return (
    <>
      {pageObjectKey === 'contact' || pageObjectKey === 'services' ? (
        <ContactFormV1
          {...props}
          businessData={businessData}
          configData={configData}
          pageTitle={pageTitle}
          isMultiLocationSite={isMultiLocationSite}
          variantName={configData.contactForm.variantName}
          options={configData.contactForm.options}
        />
      ) : (
        ''
      )}
      {/* //  ! =============================================================*/}
      {/* //  ! WHEN Shaodwing Add Additional Contact Forms Here By Page Key */}
      {/* //  ! =============================================================*/}

      {pageObjectKey === 'reservations' && (
        <Form
          // {...props}
          // businessData={businessData}
          // configData={configData}
          // pageTitle={pageTitle}
          // isMultiLocationSite={isMultiLocationSite}
          // variantName={configData.contactForm.variantName}
          // options={configData.contactForm.options}
          formName='reservations-form'
          variantName={configData.contactForm.variantName}
          city={'ny'}
          isMultiLocationSite={false}
        />
      )}
    </>
  )
}
