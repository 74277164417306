// * finds the image url given the id and the whole poweredImages object and Multilocation locationIndex
import cloudinaryImageOptimizer from './cloudinaryImageOptimizer'
export default (poweredImageId, poweredImageObjects, locationIndex) => {
  // * uses the arrayOfImageIds in to find the index position of the image
  // * faster algothrim method to find by index than loops through all the image objects looking for a match.
  const poweredImagesIndex = poweredImageObjects.arrayOfImageIds?.[
    locationIndex
  ]?.indexOf(poweredImageId)

  const imageUrl =
    poweredImageObjects?.imageObjects?.[locationIndex]?.[poweredImagesIndex]
      ?.imageUrl

  const stringComponents = imageUrl?.split(
    'https://res.cloudinary.com/gonation/'
  )

  const optimizedImage = `https://res.cloudinary.com/gonation/w_${2500}/q_auto/f_auto/${
    stringComponents?.[1]
  }`

  const notFoundImage =
    'https://res.cloudinary.com/gonation/image/upload/v1614006737/sites/general/404image.png'

  // * if the powered image url is present/found use it, else use powered images not found image

  return optimizedImage ? optimizedImage : ''
}
